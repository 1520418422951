<template>
  <div>
    <ProductsTable />
  </div>
</template>

<script>
import ProductsTable from '../components/ProductsTable.vue'

export default {
  components: {
    ProductsTable
  }
}
</script>
