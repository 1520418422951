
















































import ListingHierarchiesMixin from '../mixins/ListingHierarchiesMixin'
import ProductDialog from '../components/ProductDialog.vue'
import ODataTable from '@/components/ODataTable.vue'
import IListingHierarchy from '../models/IListingHierarchy'
import IODataTableHeader from '@/models/IODataTableHeader'
import IListingProduct from '../models/IListingProduct'
import IListing from '../models/IListing'

export default ListingHierarchiesMixin.extend({
  components: {
    ODataTable,
    ProductDialog
  },
  data() {
    return {
      activeItem: null as IListingHierarchy,
      activeListings: null as IListing[]
    }
  },
  computed: {
    headers(): IODataTableHeader[] {
      return [
        {
          text: 'Family Name',
          value: 'parent.parent.name',
          searchable: true
        },
        {
          text: 'Group Name',
          value: 'parent.name',
          searchable: true
        },
        {
          text: 'Product Name',
          value: 'name',
          searchable: true
        },
        {
          text: 'Description',
          value: 'description',
          searchable: true
        },
        {
          text: 'Comments',
          value: 'comments',
          searchable: true
        },
        {
          text: 'Sort Name',
          value: 'sortName'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    async onSave(item: IListingHierarchy) {
      await this.saveListingHierarchy(item)
      this.activeItem = null
    },
    async onDelete(item: IListingHierarchy) {
      var product = item as IListingProduct
      this.activeListings = product.listings
      await this.deleteListingHierarchy(item)
    },
    async getChildren(id: number) { // eslint-disable-line @typescript-eslint/no-unused-vars
      return this.activeListings.map(x => {
        return {
          name: x.displayName
        }
      })
    }
  }
})
